import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

const Players = ({ scene }) => {
  const clock = useRef(new THREE.Clock()); // To keep track of the animation time

  useEffect(() => {
    const loader = new FBXLoader();

    // Load the same AO map for all meshes
    const textureLoader = new THREE.TextureLoader();
    const aoMap = textureLoader.load('/models/ao.png');  // Apply the same AO map to all meshes
    aoMap.wrapS = THREE.RepeatWrapping;
    aoMap.wrapT = THREE.RepeatWrapping;
    aoMap.repeat.set(1, 1); // Adjust repeat scaling if needed

    loader.load(
      '/models/players.fbx', // Replace with your FBX file path
      (fbx) => {
        console.log('Static FBX loaded successfully');
        
        // Scale the model if needed
        fbx.scale.set(0.003, 0.003, 0.003);

        // Position the model
        fbx.position.set(0, 0.2, 0);  // Set the initial position of the whole model

        // Traverse through the model's children (meshes) and apply AO map and color dynamically
        fbx.traverse((child) => {
          if (child.isMesh) {
            // Apply MeshStandardMaterial with the AO map
            child.material = new THREE.MeshStandardMaterial({
              aoMap: aoMap,      // Apply the AO map
              aoMapIntensity: 1, // Set intensity of AO map effect
            });

            // Dynamically change the color based on the name
            if (child.name.startsWith('Player_A_')) {
              child.material.color.set('#296644'); // Green for Player_A meshes
            } else if (child.name.startsWith('Player_B_')) {
              child.material.color.set('#321C4B'); // Blue for Player_B meshes
            }

            // Enable shadows
            child.castShadow = true;
            child.receiveShadow = true;

            // Ensure the material updates with the new map and color
            child.material.needsUpdate = true;
          }
        });

        // Add the loaded FBX model to the scene
        scene.add(fbx);
      },
      // Progress callback
      (xhr) => {
        console.log('Loading:', (xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      // Error callback
      (error) => {
        console.error('Error loading static FBX:', error);
      }
    );

    // Simple animation loop (optional)
    const animate = () => {
      requestAnimationFrame(animate);
    };

    animate(); // Start the animation loop

    // Cleanup function
    return () => {
      scene.traverse((object) => {
        if (object.isMesh) {
          object.geometry.dispose();
          object.material.dispose();
        }
      });
    };
  }, [scene]);

  return null; // This component doesn't render any DOM elements
};

export default Players;







