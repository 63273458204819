import React, { useEffect } from 'react';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

const Field = ({ scene }) => {
  useEffect(() => {
    const loader = new FBXLoader();

    loader.load(
      '/models/field_with_logo.fbx',
      (fbx) => {
        console.log('Static FBX loaded successfully');
        fbx.scale.set(0.0003, 0.0003, 0.0003);
        const box = new THREE.Box3().setFromObject(fbx);
        const center = box.getCenter(new THREE.Vector3());
        fbx.position.sub(center);
        scene.add(fbx);
      },
      (progress) => {
        console.log('Loading:', (progress.loaded / progress.total) * 100 + '%');
      },
      (error) => {
        console.error('Error loading static FBX:', error);
      }
    );
  }, [scene]);

  return null;
};

export default Field;
