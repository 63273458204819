import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import Field from './Field';
import Players from './Players';

const SceneRendererComponent = () => {
  const mountRef = useRef(null);
  const arrowsRef = useRef([]);

  const scene = useRef(new THREE.Scene());
  const camera = useRef(new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  ));
  const renderer = useRef(new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
    premultipliedAlpha: false,
  }));
  const controls = useRef(null);

  useEffect(() => {
    // Initialize renderer
    renderer.current.setSize(window.innerWidth, window.innerHeight);
    renderer.current.setClearColor(0x000000, 0);
    renderer.current.shadowMap.enabled = true;
    mountRef.current.appendChild(renderer.current.domElement);

    // Set up camera
    camera.current.position.set(0, 2, 5);
    camera.current.lookAt(0, 0, 0);

    // Lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 5.5);
    scene.current.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(2, 5, 5);
    directionalLight.castShadow = true;
    scene.current.add(directionalLight);

    // Set up controls
    controls.current = new OrbitControls(camera.current, renderer.current.domElement);
    controls.current.enableDamping = true;

    // Clock for animations
    const clock = new THREE.Clock();

    // Animation loop
    function animate() {
      requestAnimationFrame(animate);
      controls.current.update();
      renderer.current.render(scene.current, camera.current);
    }
    animate();

    // Handle window resizing
    const handleResize = () => {
      camera.current.aspect = window.innerWidth / window.innerHeight;
      camera.current.updateProjectionMatrix();
      renderer.current.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.current.domElement);
      }
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(1, 1, 1, 1)',
      }}
    >
      <ArrowLeft scene={scene.current} arrowsRef={arrowsRef} />
      {/* <ArrowRight scene={scene.current} arrowsRef={arrowsRef} /> */}
      <Field scene={scene.current} />
      <Players scene={scene.current} />
    </div>
  );
};

export default SceneRendererComponent;
















