import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

const ArrowLeft = ({ scene, arrowsRef }) => {
  const speedIncrement = useRef(0.01);
  const lastSpeedUpdateTime = useRef(0);
  const maxSpeedTime = useRef(10);
  const maxSpeed = useRef(0.1);

  useEffect(() => {
    const loader = new FBXLoader();

    loader.load(
      '/models/staticNewArrow3.fbx',
      (fbx) => {
        console.log('Arrow FBX loaded successfully');
        const alphaMap = new THREE.TextureLoader().load('/models/mapArrowTest3.png');
        alphaMap.wrapS = THREE.RepeatWrapping;
        alphaMap.wrapT = THREE.RepeatWrapping;
        alphaMap.repeat.set(0.5, 1);

        fbx.traverse((child) => {
          if (child.isMesh) {
            const material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0x0000ff),
              side: THREE.DoubleSide,
              transparent: true,
              alphaMap: alphaMap,
              roughness: 0.5,
              metalness: 0.1,
            });
            child.material = material;
            // Push arrow meshes to `arrowsRef`
            arrowsRef.current.push(child);
          }
        });

        // Position and scale
        fbx.scale.set(0.0035, 0.003, 0.007);
        const box = new THREE.Box3().setFromObject(fbx);
        const center = box.getCenter(new THREE.Vector3());
        fbx.position.sub(center);
        fbx.position.set(-0.6, -0.5, -0.02);
        scene.add(fbx);
      },
      (progress) => {
        console.log('Loading:', (progress.loaded / progress.total) * 100 + '%');
      },
      (error) => {
        console.error('Error loading FBX:', error);
      }
    );

    // Animation loop
    const clock = new THREE.Clock();
    const animate = () => {
      const elapsedTime = clock.getElapsedTime();

      // Update speed increment based on elapsed time
      if (elapsedTime - lastSpeedUpdateTime.current >= 4 && elapsedTime <= maxSpeedTime.current) {
        speedIncrement.current += 0.0009;
        lastSpeedUpdateTime.current = elapsedTime;
      }

      // Ensure speed does not exceed maxSpeed
      const speed = Math.min(speedIncrement.current, maxSpeed.current);

      // Update arrows' alphaMap offset for animation
      arrowsRef.current.forEach((arrow) => {
        if (arrow.material && arrow.material.alphaMap) {
          arrow.material.alphaMap.offset.x += speed;
        }
      });

      // Request next frame
      requestAnimationFrame(animate);
    };

    animate(); // Start animation

    // Cleanup function
    return () => {
      arrowsRef.current.forEach((arrow) => {
        scene.remove(arrow);
      });
      arrowsRef.current = [];
    };
  }, [scene, arrowsRef]);

  return null;
};

export default ArrowLeft;

